<template>
  <Section>
    <FadeIn>
  
      <Container v-if="props.content.type === 'box'">
        <div class="bg-white border-2 border-[#e8e5de] rounded-[20px] md:rounded-[35px] py-8 md:py-16 lg:py-24">
          <div class="text-center">
            <h2 class="text-section-title">Trusted across all sectors</h2>
            <p class="text-subtitle">Join a growing list of organisations making a difference</p>
          </div>
          
          <div class="relative mx-auto mt-8 sm:mt-16">
            
            <div 
            class="absolute top-0 left-0 h-full w-[50px] md:w-[80px] lg:w-[100px] z-10"
            style="background: linear-gradient(270deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);"
            ></div>

            <div 
            class="absolute top-0 right-0 h-full w-[50px] md:w-[80px] lg:w-[100px] z-10"
            style="background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);"
            ></div>
            
            <Vue3Marquee
            :gradient="true"
            :gradient-color="[255,255,255]"
            gradient-length="25%"
            >
              <!-- <img 
              v-for="logo in logos" 
              :src="useCdn(logo.image_id.image.id, logo.image_id.image.filename_download)+'?width=200'" 
              :width="logo.image_id.image.width" 
              :height="logo.image_id.image.height" 
              :alt="`Trusted by Elker - ${logo.image_id.caption}`"
              loading="lazy"
              class="max-w-[85px] xs:max-w-[90px] md:max-w-[100px] sm:max-w-[110px] filter grayscale mx-4 md:mx-6"
              > -->
              <img src="https://media.elker.com/f122169b-dcbf-4f34-ab5e-e83e916aa06e/choices_and_rights_logo_elker.png?width=200" width="512" height="348" alt="Trusted by Elker - Choices &amp; Rights" loading="lazy" class="max-w-[85px] xs:max-w-[90px] md:max-w-[100px] sm:max-w-[110px] filter grayscale mx-4 md:mx-6">
              <img src="https://media.elker.com/523f8e6b-e16b-4dd5-ab5e-afa1a3db470f/victorian_legal_services_board_and_commissioner_logo_elker.png?width=200" width="512" height="90" alt="Trusted by Elker - Victorian Legal Services Board and Commissioner" loading="lazy" class="max-w-[85px] xs:max-w-[90px] md:max-w-[100px] sm:max-w-[110px] filter grayscale mx-4 md:mx-6">
              <img src="https://media.elker.com/b7838113-e46f-4045-8c96-3ef07f930ab5/university-melbourne-logo.png?width=200" width="900" height="256" alt="Trusted by Elker - The University of Melbourne" loading="lazy" class="max-w-[85px] xs:max-w-[90px] md:max-w-[100px] sm:max-w-[110px] filter grayscale mx-4 md:mx-6">
              <img src="https://media.elker.com/769c06e4-dd2f-4e37-92ef-9544fc7bc690/Allens_Logo_Elker.png?width=200" width="2000" height="667" alt="Trusted by Elker - Allens" loading="lazy" class="max-w-[85px] xs:max-w-[90px] md:max-w-[100px] sm:max-w-[110px] filter grayscale mx-4 md:mx-6">
              <img src="https://media.elker.com/3408f664-87ee-4351-af16-c53492659188/fujitsu_logo_elker.png?width=200" width="2378" height="1294" alt="Trusted by Elker - Fujitsu" loading="lazy" class="max-w-[85px] xs:max-w-[90px] md:max-w-[100px] sm:max-w-[110px] filter grayscale mx-4 md:mx-6">
              <img src="https://media.elker.com/126bfaaa-aa0d-4eba-9941-f3facfee009c/office_of_the_legal_services_commissioner_nsw_logo_elker.png?width=200" width="512" height="175" alt="Trusted by Elker - Office of the Legal Services Commissioner NSW" loading="lazy" class="max-w-[85px] xs:max-w-[90px] md:max-w-[100px] sm:max-w-[110px] filter grayscale mx-4 md:mx-6">
              <img src="https://media.elker.com/e5cb14db-d270-44a5-b345-376c3869eff8/solotel_logo_elker.png?width=200" width="512" height="59" alt="Trusted by Elker - Solotel" loading="lazy" class="max-w-[85px] xs:max-w-[90px] md:max-w-[100px] sm:max-w-[110px] filter grayscale mx-4 md:mx-6">
              <img src="https://media.elker.com/4322f1da-4fdb-45b2-85f9-68c892f67821/womens_community_shelters_logo.png?width=200" width="512" height="241" alt="Trusted by Elker - Women's Community Shelters" loading="lazy" class="max-w-[85px] xs:max-w-[90px] md:max-w-[100px] sm:max-w-[110px] filter grayscale mx-4 md:mx-6">
              <img src="https://media.elker.com/9b55a937-2ee0-4def-9713-99696df2a21e/youngcare_logo.png?width=200" width="512" height="237" alt="Trusted by Elker - YoungCare" loading="lazy" class="max-w-[85px] xs:max-w-[90px] md:max-w-[100px] sm:max-w-[110px] filter grayscale mx-4 md:mx-6">
              <img src="https://media.elker.com/4e838cef-29d6-4d46-bebd-966c1de6d4b2/lpbt_logo.png?width=200" width="346" height="198" alt="Trusted by Elker - Legal Profession Board of Tasmania" loading="lazy" class="max-w-[85px] xs:max-w-[90px] md:max-w-[100px] sm:max-w-[110px] filter grayscale mx-4 md:mx-6">
              <img src="https://media.elker.com/aed31c9b-9a1e-477f-9614-81f40c35ae6e/stanwell_corporation.webp?width=200" width="200" height="200" alt="Trusted by Elker - Stanwell" loading="lazy" class="max-w-[85px] xs:max-w-[90px] md:max-w-[100px] sm:max-w-[110px] filter grayscale mx-4 md:mx-6">
              <img src="https://media.elker.com/c227ef69-0887-4487-b2c8-8f7b6ee3c480/Legal_Practice_Board_Elker_Speak_Safely.png?width=200" width="500" height="92" alt="Trusted by Elker - Legal Practice Board of WA" loading="lazy" class="max-w-[85px] xs:max-w-[90px] md:max-w-[100px] sm:max-w-[110px] filter grayscale mx-4 md:mx-6">
            </Vue3Marquee>

          </div>
        </div>
      </Container>
  
      <div v-else>
        <Container>
          <div class="text-center">
            <div>
              Building <strong>trust and transparency</strong> with
            </div>
          </div>
          <div class="relative mx-auto mt-8 sm:mt-16 h-[85px] sm:h-[110px] lg:h-[100px]">
            <div 
            class="absolute top-0 left-0 h-full w-[50px] md:w-[80px] lg:w-[100px] z-10"
            style="background: linear-gradient(270deg, rgba(249, 248, 246,0) 0%, rgba(249, 248, 246,1) 100%);"
            ></div>

            <div 
            class="absolute top-0 right-0 h-full w-[50px] md:w-[80px] lg:w-[100px] z-10"
            style="background: linear-gradient(90deg, rgba(249, 248, 246,0) 0%, rgba(249, 248, 246,1) 100%);"
            ></div>
            
            <Vue3Marquee
            :gradient="true"
            :gradient-color="[249, 248, 246]"
            gradient-length="25%"
            >
              <!-- <img 
              v-for="logo in logos" 
              :src="useCdn(logo.image_id.image.id, logo.image_id.image.filename_download)+'?width=200'" 
              :width="logo.image_id.image.width" 
              :height="logo.image_id.image.height" 
              :alt="`Trusted by Elker - ${logo.image_id.caption}`"
              loading="lazy"
              class="max-w-[85px] xs:max-w-[90px] md:max-w-[100px] sm:max-w-[110px] filter grayscale mx-4 md:mx-6"
              > -->
              <img src="https://media.elker.com/f122169b-dcbf-4f34-ab5e-e83e916aa06e/choices_and_rights_logo_elker.png?width=200" width="512" height="348" alt="Trusted by Elker - Choices &amp; Rights" loading="lazy" class="max-w-[85px] xs:max-w-[90px] md:max-w-[100px] sm:max-w-[110px] filter grayscale mx-4 md:mx-6">
              <img src="https://media.elker.com/523f8e6b-e16b-4dd5-ab5e-afa1a3db470f/victorian_legal_services_board_and_commissioner_logo_elker.png?width=200" width="512" height="90" alt="Trusted by Elker - Victorian Legal Services Board and Commissioner" loading="lazy" class="max-w-[85px] xs:max-w-[90px] md:max-w-[100px] sm:max-w-[110px] filter grayscale mx-4 md:mx-6">
              <img src="https://media.elker.com/b7838113-e46f-4045-8c96-3ef07f930ab5/university-melbourne-logo.png?width=200" width="900" height="256" alt="Trusted by Elker - The University of Melbourne" loading="lazy" class="max-w-[85px] xs:max-w-[90px] md:max-w-[100px] sm:max-w-[110px] filter grayscale mx-4 md:mx-6">
              <img src="https://media.elker.com/769c06e4-dd2f-4e37-92ef-9544fc7bc690/Allens_Logo_Elker.png?width=200" width="2000" height="667" alt="Trusted by Elker - Allens" loading="lazy" class="max-w-[85px] xs:max-w-[90px] md:max-w-[100px] sm:max-w-[110px] filter grayscale mx-4 md:mx-6">
              <img src="https://media.elker.com/3408f664-87ee-4351-af16-c53492659188/fujitsu_logo_elker.png?width=200" width="2378" height="1294" alt="Trusted by Elker - Fujitsu" loading="lazy" class="max-w-[85px] xs:max-w-[90px] md:max-w-[100px] sm:max-w-[110px] filter grayscale mx-4 md:mx-6">
              <img src="https://media.elker.com/126bfaaa-aa0d-4eba-9941-f3facfee009c/office_of_the_legal_services_commissioner_nsw_logo_elker.png?width=200" width="512" height="175" alt="Trusted by Elker - Office of the Legal Services Commissioner NSW" loading="lazy" class="max-w-[85px] xs:max-w-[90px] md:max-w-[100px] sm:max-w-[110px] filter grayscale mx-4 md:mx-6">
              <img src="https://media.elker.com/e5cb14db-d270-44a5-b345-376c3869eff8/solotel_logo_elker.png?width=200" width="512" height="59" alt="Trusted by Elker - Solotel" loading="lazy" class="max-w-[85px] xs:max-w-[90px] md:max-w-[100px] sm:max-w-[110px] filter grayscale mx-4 md:mx-6">
              <img src="https://media.elker.com/4322f1da-4fdb-45b2-85f9-68c892f67821/womens_community_shelters_logo.png?width=200" width="512" height="241" alt="Trusted by Elker - Women's Community Shelters" loading="lazy" class="max-w-[85px] xs:max-w-[90px] md:max-w-[100px] sm:max-w-[110px] filter grayscale mx-4 md:mx-6">
              <img src="https://media.elker.com/9b55a937-2ee0-4def-9713-99696df2a21e/youngcare_logo.png?width=200" width="512" height="237" alt="Trusted by Elker - YoungCare" loading="lazy" class="max-w-[85px] xs:max-w-[90px] md:max-w-[100px] sm:max-w-[110px] filter grayscale mx-4 md:mx-6">
              <img src="https://media.elker.com/4e838cef-29d6-4d46-bebd-966c1de6d4b2/lpbt_logo.png?width=200" width="346" height="198" alt="Trusted by Elker - Legal Profession Board of Tasmania" loading="lazy" class="max-w-[85px] xs:max-w-[90px] md:max-w-[100px] sm:max-w-[110px] filter grayscale mx-4 md:mx-6">
              <img src="https://media.elker.com/aed31c9b-9a1e-477f-9614-81f40c35ae6e/stanwell_corporation.webp?width=200" width="200" height="200" alt="Trusted by Elker - Stanwell" loading="lazy" class="max-w-[85px] xs:max-w-[90px] md:max-w-[100px] sm:max-w-[110px] filter grayscale mx-4 md:mx-6">
              <img src="https://media.elker.com/c227ef69-0887-4487-b2c8-8f7b6ee3c480/Legal_Practice_Board_Elker_Speak_Safely.png?width=200" width="500" height="92" alt="Trusted by Elker - Legal Practice Board of WA" loading="lazy" class="max-w-[85px] xs:max-w-[90px] md:max-w-[100px] sm:max-w-[110px] filter grayscale mx-4 md:mx-6">

            </Vue3Marquee>

          </div>
        </Container>
      </div>
    </FadeIn>
  </Section>
</template>

<script setup>
import { Vue3Marquee } from 'vue3-marquee'
const { getItems } = useDirectusItems()

const props = defineProps({
  content: {
    type: Object,
    required: true,
  }
})

// THIS IS FOR SPEED - NOT HAVING TO DO ANOTHER ROUND TRIP TO THE API
// const logos = ref([])
// const { data } = await useAsyncData(
//   'block-logos',
//   () => getItems({
//     collection: 'global',
//     params: {
//       fields: [
//         'logos.image_id.*',
//         'logos.image_id.image.id',
//         'logos.image_id.image.filename_download',
//         'logos.image_id.image.width',
//         'logos.image_id.image.height'
//       ]
//     },
//   })
// )

// logos.value = data.value.logos
</script>